.flex
  display: flex

.fdc
  flex-direction: column

.aic
  align-items: center
.aife
  align-items: flex-end
.as
  &-fe
    align-self: flex-end

.jcc
  justify-content: center
.jcsb
  justify-content: space-between

.fgrow
  flex-grow: 1
.gap
  &-48
    gap: 48px

.w
  &-500
    max-width: 500px
    width: 500px

.bb
  border-bottom: 2px solid $pr_dark
.bt
  border-top: 2px solid $pr_dark
.bl
  border-left: 2px solid $pr_dark
.br
  border-right: 2px solid $pr_dark

.pl
  &-6
    padding-left: 24px
  &-25
    padding-left: 100px
.pb
  &-4
    padding-bottom: 16px

.mb
  &-4
    margin-bottom: 16px
  &-8
    margin-bottom: 32px
  &-12
    margin-bottom: 48px
.ml
  &-2
    margin-left: 8px
.mr
  &-5
    margin-right: 20px
 

.textr
  text-align: right
.textc
  text-align: center
.tup
  text-transform: uppercase

.text-dec-n
  text-decoration: none
  
  