.lb1
  display: flex
  width: 88px
  height: 40px
  justify-content: center
  align-items: center
  gap: 8px
  flex-shrink: 0
  border-radius: 20px
  border: 2px solid $pr_dark
  background-color: transparent
  color: $pr_dark
  text-align: center
  cursor: pointer
  transition: 0.3s
  &:hover
    background-color: $tr_blue
  &::after
    content: ''
    font-family: Manrope
    font-size: 16px
    font-style: normal
    font-weight: 600
    line-height: normal
    letter-spacing: 0.64px
    text-transform: uppercase

.check-box
  display: none
  &+.lb1::after
    content: attr(data-Label)
  &:checked+.lb1
    background-color: $pr_dark
    color: white
    border-color: $pr_dark
  &:checked+.lb1::after
    content: attr(data-checkedLabel)

.text-input
  font-family: Manrope
  font-size: 16px
  font-weight: 400
  letter-spacing: 0.48px
  resize: none
  list-style: 30px

.input
  width: 100%
  margin-bottom: 40px
  &-label
    @extend .text-input
    display: block
    color: $pr_dark
    text-transform: uppercase
  &-field
    @extend .text-input
    width: 100%
    min-height: 30px
    border: none
    border-bottom: 2px solid $pr_dark
    margin: 16px 0
    color: $pr_dark
    &:focus
      outline: none
  &-field::placeholder
    @extend .text-input
    color: $sec_gray
  &-error
    @extend .text-input
    color: $pr_dark
    gap: 5px
    &.error
      color: $alarm