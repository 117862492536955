.h1
  font-size: 96px
  font-weight: 800
  font-family: Manrope
.h2
  font-size: 72px
  font-weight: 800
  font-family: Manrope
.h3
  font-size: 32px
  font-weight:700
  font-family: Manrope
.h4
  font-size: 32px
  font-weight: 500
  font-family: Manrope
.h5
  font-size: 20px
  font-weight: 400
  font-family: Manrope
.body-text1
  font-size: 32px
  font-weight: 400
  font-family: Manrope
.body-text2
  font-size: 32px
  font-weight: 500
  font-family: Inter
.body-text3
  font-size: 24px
  font-weight: 800
  font-family: Manrope
.body-text4
  font-size: 24px
  font-weight: 500
  font-family: Manrope
.body-text5
  font-size: 24px
  font-weight: 400
  font-family: Inter
.body-text6
  font-size: 20px
  font-weight: 400
  font-family: Inter
.body-text7
  font-size: 16px
  font-weight: 500
  font-family: Manrope
.button-text
  font-size: 20px
  font-weight: 700
  font-family: Manrope
.button-input
  font-size: 16px
  font-weight: 400
  font-family: Manrope