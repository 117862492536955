.big-button
  display: flex
  padding: 16px 24px
  justify-content: center
  align-items: center
  gap: 10px
  border-radius: 30px
  text-align: center
  font-family: Manrope
  font-size: 20px
  font-style: normal
  font-weight: 600
  line-height: normal
  letter-spacing: 0.8px
  text-transform: uppercase
  cursor: pointer
  transition: 0.3s

.pr-btn
  @extend .big-button
  min-width: 282px
  background-color: $pr_lemon
  color: $pr_dark
  border: none
  &:hover
    background-color: $sec_gray
    color: $sec_light
  &:disabled
    background-color: $tr_light
    color: $sec_light
    cursor: auto

.sec-btn
  @extend .big-button
  border: 2px solid $pr_dark
  background-color: white
  &:hover
    color: $sec_gray
    border-color: $sec_gray
  &:disabled
    border-color: $tr_light
    color: $tr_light
    cursor: auto

.menu-btn
  padding: 10px 15px
  flex-shrink: 0
  border-top-left-radius: 30px
  border-top-right-radius: 30px
  border-bottom-right-radius: 30px
  background-color: white
  border: none
  color: $pr_dark
  text-align: center
  font-family: Manrope
  font-size: 20px
  font-style: normal
  font-weight: 600
  line-height: normal
  letter-spacing: 0.8px
  text-transform: uppercase
  cursor: pointer
  transition: 0.3s
  &:hover
    background-color: $pr_lemon
  &.active
    background-color: $pr_lemon

.menu-chat-btn
  padding: 5px
  display: flex
  width: 46px
  height: 46px
  font-size: 36px
  justify-content: center
  align-items: center
  flex-shrink: 0
  border: none
  background-color: transparent
  cursor: pointer
  transition: 0.3s
  &:hover
    color: $pr_lemon

.icon-btn
  display: inline-flex
  padding: 14px
  align-items: flex-start
  gap: 10px
  border-radius: 26px
  border: 2px solid $pr_dark
  background-color: transparent
  color: $pr_dark
  font-size: 24px
  cursor: pointer
  transition: 0.3s
  &:hover
    border-color: $sec_gray
    color: $sec_gray
  &:disabled
    border-color: $tr_light
    color: $tr_light
    cursor: auto







