@import '_variables'

*
  margin: 0
  padding: 0
  box-sizing: border-box

.container
  max-width: 1240px
  min-height: 100vh
  height: 100vh
  width: 100%
  margin: 0 auto
  padding: 16px 20px
  display: flex
  flex-direction: column

#header
  border-bottom: 2px solid $pr_dark

#main
  #intro
    height: 689px
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    .intro-text
      display: flex
      flex-direction: column
      flex-grow: 1
      //padding-right: 10%
      .h1
        text-transform: uppercase
        width: 90%
        color: $pr_lemon
        text-shadow: 0px 1px 0 $pr_dark, 0px -1px 0 $pr_dark,1px 0px 0 $pr_dark,-1px 0px 0 $pr_dark,1px 1px 0 $pr_dark,1px -1px 0 $pr_dark,-1px 1px 0 $pr_dark,-1px -1px 0 $pr_dark
      .body-text6
        width: 90%
        line-height: 140%
        letter-spacing: 0.6px
        text-align: justify
        color: $pr_dark
        padding-bottom: 32px
      .buttons
        width: 90%
    .intro-image
      widht: 515px
      height: 100%
      padding-left: 24px
      border-left: 2px solid $pr_dark
      display: flex
  #promo1
    padding: 65px 0
    .h2
      text-transform: uppercase
      color: $pr_dark
    .body-text1,
    .h3
      color: $pr_dark
      width: 378px
      text-transform: uppercase
      margin: 0 auto
    .body-text1
      margin-bottom: 10px
  #business
    height: 539px
    display: flex
    align-items: center
    .business-image
      display: flex
      align-items: center
      width: 485px
      height: 100%
    .pl-6
      width: 715px
      &.sec
        zoom: .97
    .h3
      text-transform: uppercase
      color: $pr_dark
    .body-text2
      color: $pr_dark
  #promo2
    padding: 63px 0
    .promo2-inner
      width: 690px
      margin: 0 auto
      .body-text4
        text-transform: uppercase
        color: $pr_dark
        margin-bottom: 32px
        line-height: 140%
        letter-spacing: 0.72px
  #benefits
    min-height: 980px
    padding: 33px 0
    display: flex
    align-items: center
    justify-content: space-between
    .benefits-image
      height: 100%
      align-self: flex-start
      .h3
        margin-bottom: 107px
        text-transform: uppercase
    .benefits-text
      display: flex
      align-items: center
      flex-grow: 1
      .benefits-inner
        .block
          padding-left: 25px
          margin-bottom: 32px
          .h3
            margin-bottom: 24px
            text-transform: uppercase
          .body-text5
            margin-bottom: 32px
  #promo3
    padding: 63px 0
    .promo3-inner
      width: 690px
      margin: 0 auto
      .h4
        text-transform: uppercase
      .body-text4
        margin: 24px 0 32px 0
        text-transform: uppercase
  #publications
    height: 657px
    padding: 104px 0
    display: flex
    justify-content: space-between
    .publications-text
      width: 612px
      .embla
        overflow: hidden
      .embla__container
        display: flex
        gap: 10px
      .embla__slide
        flex: 0 0 100%
        min-width: 0
    .image
      width: 485px
    .section-title
      text-transform: uppercase
      margin-bottom: 40px
    .publication-title
      margin-bottom: 16px
    .body-text5
      margin-bottom: 40px
      min-height: 170px
    .sec-btn
      width: 282px


#footer
  justify-self: end
  padding: 24px 0
  height: 200px
  .body-text6
    letter-spacing: 1.2px

#business-form,
#pr-form
  .wrapper
    display: flex
    justify-content: space-between
    margin: 100px 0
    form
      width: 612px
      .sec-btn
        min-width: 282px
    .download
      display: flex
      gap: 48px
      margin-bottom: 48px
      .inner
        display: flex
        flex-direction: column
        align-items: center
        gap: 8px
        padding: 32px 24px
        text-align: center
        border: 1px dashed $pr_dark
        background-color: $tr_blue
        .input-file
          position: absolute
          visibility: hidden
          opacity: 0
        .label
          color: $pr_dark
          font-family: Inter
          font-size: 12px
          line-height: 140%
          letter-spacing: 0.36px
          .icon
            font-size: 40px


.logo
  width: 80px
  height: 80px
  border-radius: 40px 40px 40px 0px
  background-color: $pr_lemon
  padding-left: 16px
  color: $pr_dark
  letter-spacing: 2.16px
  text-transform: uppercase

.item
  width: 32px
  height: 32px
  border-radius: 48px 48px 48px 0px
  background-color: $pr_lemon
  margin-right: 24px
